import React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding: 2rem;
  border-left: 4px solid ${props => props.theme.colors.error};
  margin-top: 2rem;

  h3 {
    font-size: 1.8rem
    margin: 0; padding: 0;
    margin-bottom: 1rem
  }
  p, li { margin: 0; padding: 0; font-size: 1.5rem; }
  ul { margin-bottom: 0; }

  a {
    color: ${props => props.theme.colors.primary};
  }
`

const FormErrors = ({ errors }) => {
  if (errors.length <= 0) {
    return null
  }

  return (
    <Container>
      {errors.map((error, index) => {
        return <p key={`formError-${index}`}>{error}</p>
      })}
    </Container>
  )
}
export default FormErrors
